import React from 'react';
import HomePage from './components/HomePage';
import './styles/variables.css';

function App() {
  return (
    <div className="apptopdiv">
      <HomePage />
    </div>
  )
}

export default App;
